<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-card-title class="mb-1"> Verification </b-card-title>
        <b-spinner
          v-if="loading"
          variant="primary"
          type="grow"
          label="Spinning"
        />
        <!-- No Error -->
        <div v-else-if="!error">
          <b-card-text class="mb-2 text-success">
            Congrats, your account has been verified
          </b-card-text>
          <b-button
            @click="$router.push({ name: 'login' })"
            block
            type="submit"
            variant="primary"
          >
            Login now
          </b-button>
        </div>
        <!-- Error -->
        <b-card-text v-else class="mb-2 text-danger">
          Oops, Faild to verify your account..
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import Auth from "@/services/auth.service.js";
import { BCard, BCardTitle, BCardText, BButton } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BButton,
    BCardTitle,
    BCardText,
  },
  data: () => ({
    loading: true,
    error: false,
  }),
  async mounted() {
    this.loading = true;
    try {
      await Auth.verifyEmail(this.$route.params.token);
    } catch {
      this.error = true;
    }
    this.loading = false;
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
